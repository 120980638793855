<template>
  <suc-text-field-component v-bind="$attrs" v-on="$listeners"></suc-text-field-component>
</template>

<script>
import { SucTextFieldComponent } from "@/components/form";

export default {
  components: {
    SucTextFieldComponent,
  },
};
</script>
